/* requires:
polyfill.js
*/

// custom script for bootstrap video modal, only needed for page with video modals
let videoUrl;
$('[data-toggle=modal]').on('click', function() {
  videoUrl = $(this).data('src');
  const target = $(this).data('target')
  $(target).find('iframe').attr('src', videoUrl);
});
$(document).on('hide.bs.modal','.video-modal', function () {
  $(this).find('iframe').attr('src', '');
})
$(document).on('show.bs.modal','.video-modal', function () {
  $(this).find('iframe').attr('src', videoUrl);
})

// create video modal node after this script
const videoModal = document.createElement('div');
videoModal.setAttribute('id', 'video-modal');
videoModal.setAttribute('class', 'modal fade video-modal');
const videoModalHtml = `<div class="modal-dialog modal-lg modal-dialog-centered">
                          <div class="modal-content">
                            <div class="modal-body p-1">
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                              <div class="embed-responsive embed-responsive-16by9">
                                <iframe src="" loading="lazy" width="853" height="480" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                              </div>
                            </div>
                          </div>
                        </div>`
videoModal.innerHTML = videoModalHtml;
document.body.appendChild(videoModal);
